import React from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import "./style.scss";

import { AdminMessageCard } from "../../components/adminMessage/adminMessageCard";
import { AdminMessageFilter } from "../../components/adminMessage/adminMessageFilter";

import { Toolbar } from "../../components/toolbar";
import { AppState } from "../../store/store";
import { adminMessageType } from "../../store/user/types";
import { addNewMessage } from "../../store/user/userSlice";
import { getNewMessage } from "../../development/initializer";
import { cPhoneWidthLim } from "../../app";
import { useTranslation } from "react-i18next";

export const Announcements: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const width = useSelector((state: AppState) => state.scheduler.screenWidth);
  const height = useSelector((state: AppState) => state.scheduler.screenHeight);

  const messages = useSelector((state: AppState) => state.user.messages);
  const unsentMessages = messages.filter((message) => !message.sentToServer);
  const sentMessages = messages.filter((message) => message.sentToServer);

  let currentlyEditingMessage = false;
  for (let i = 0; i < messages.length; i++) {
    if (messages[i].sentToServer === false) {
      currentlyEditingMessage = true;
      break;
    }
  }

  return (
    <div
      className="AnnouncementsContainer"
      style={
        width <= cPhoneWidthLim || height <= cPhoneWidthLim
          ? {}
          : { position: "relative", left: "44px", paddingRight: "44px" }
      }
    >
      <div>
        <Toolbar>
          <h5>{t("menu.adminMessagePageName")}</h5>
        </Toolbar>
      </div>
      <div className="AnnouncementsWorkSpace">
        <AdminMessageFilter />
        {!currentlyEditingMessage &&
          <img
            style={{ padding: "10px" }}
            src="./icons/plus.svg"
            alt=""
            onClick={() => {
              const newMessage = getNewMessage();
              dispatch(addNewMessage(newMessage));
            }}
          />
        }
        {unsentMessages.map((message: adminMessageType) => (
          <AdminMessageCard message={message} key={message.id} />
        ))}
        {sentMessages.slice().reverse().map((message: adminMessageType) => (
          <AdminMessageCard message={message} key={message.id} />
        ))}
      </div>
    </div>
  );
};
