import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/store";
import { cPhoneWidthLim, cPhoneWidthLimStandard } from "../../../app";
import {
  getMobileOperatingSystem,
  measurementColors,
} from "../../../helpers/genericHelpers";
import { AppState } from "../../../store/store";
import {
  fetchInitialData,
  setDataPoints,
  setMeasurementToDisplay,
  setSelectedChart,
} from "../../../store/plot/plotSlice";
import { GroupChartDataProps } from "../../../store/plot/types";
import { measurementKeys } from "../../../store/scheduler/types";
import { setNotification } from "../../../store/user/userSlice";
import { checkTrafikverketError } from "../../scheduler/plan/measurementList/statusProgress";
import { ChartDimensionProps } from "../chartContainer/chartContainer";
import "./toggleMeasurements.css";

interface ToggleMeasurementsProps {
  chartID: string;
  chartDimensions: ChartDimensionProps | undefined;
}

export const ToggleMeasurements: React.FunctionComponent<
  ToggleMeasurementsProps
> = ({ chartID, chartDimensions }) => {
  const { t } = useTranslation();
  const measurements = [
    "trackGauge",
    "crossLevel",
    "crossLevelBIS",
    "crossLevelUnevenness",
    "twist6m",
    "twist3m",
    "longitudinalLevel",
    "alignmentLeft",
    "alignmentRight",
    "longitudinalLevelLeft",
    "longitudinalLevelRight",
    "alignment",
    "longitudinalAngleHP",
  ];

  const [width, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(document.documentElement.clientWidth);
    };
    if (getMobileOperatingSystem() === "iOS") {
      window.removeEventListener("resize", onResize);
      window.addEventListener("resize", onResize);
    }
    return () => {
      if (getMobileOperatingSystem() === "iOS")
        window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(document.documentElement.clientWidth);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const selectedChart = useSelector((state: AppState) =>
    state.plot.charts.find((chart) => chart.chartID === chartID)
  );

  const measurementToDisplay = selectedChart?.measurementToDisplay || [];

  return (
    <div
      className="MeasurementSelectionDropDown"
      style={{
        marginBottom: width <= cPhoneWidthLim ? "15px" : "0px",
      }}
    >
      <ToggleMeasurementsMultiSelect
        chartID={chartID}
        measurements={measurements}
        chartDimensions={chartDimensions}
      />
    </div>
  );
};

interface ToggleMeasurementsMultiSelectProps {
  chartID: string;
  measurements: string[];
  chartDimensions: ChartDimensionProps | undefined;
}

export const ToggleMeasurementsMultiSelect: React.FunctionComponent<
  ToggleMeasurementsMultiSelectProps
> = ({ chartID, measurements, chartDimensions }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedChart = useSelector((state: AppState) =>
    state.plot.charts.find((chart) => chart.chartID === chartID)
  );

  const measurementToDisplay = selectedChart?.measurementToDisplay || [];
  const selectedMeasurement = useSelector(
    (state: AppState) => state.scheduler.selectedMeasurement
  );
  const signalInterval = useSelector(
    (state: AppState) => state.plot.globalSignalInterval
  );
  const width = useSelector((state: AppState) => state.scheduler.screenWidth);

  const [loadingData, setLoadingData] = useState(false);
  const [dropDownValue, setDropDownValue] = useState<string | null>(null);
  const multiSelectRefCloseMe = useRef<any>(null);

  const setsToPlot: GroupChartDataProps = selectedChart?.plotData || {
    trackGauge: [],
    crossLevel: [],
    crossLevelBIS: [],
    crossLevelUnevenness: [],
    twist3m: [],
    twist6m: [],
    alignment: [],
    longitudinalLevel: [],
    alignmentLeft: [],
    alignmentRight: [],
    longitudinalLevelLeft: [],
    longitudinalLevelRight: [],
    longitudinalAngleHP: [],
  };

  const popupSettings = {
    className: "popupSettings",
    width: chartDimensions
      ? `${chartDimensions?.width + chartDimensions.offsetLeft + 24}px`
      : "inherent",
  };

  const handleDropDownChange = async (event: any) => {
    const selectedValue = event.target.value;
    setDropDownValue(selectedValue);

    if (!measurementToDisplay.includes(selectedValue)) {
      // Add measurement to display
      dispatch(setSelectedChart(chartID));

      try {
        const plotData = await dispatch(
          fetchInitialData({
            measurement: selectedValue,
            chartID,
            signalInterval,
            measurementID: selectedMeasurement,
            screenWidth: width,
            reset: true,
          })
        ).unwrap();
        
        dispatch(
          setDataPoints({
            ...setsToPlot,
            [selectedValue]: plotData,
          })
        );
        dispatch(
          setMeasurementToDisplay([...measurementToDisplay, selectedValue])
        );
      } catch (error) {
        dispatch(
          setNotification({
            style: "error",
            message: t(
              "toggleMeasurements.couldNotFindMeasurementDataIsMeasurementSelected"
            ),
            open: true,
          })
        );
      }
    } else {
      // Remove measurement
      dispatch(
        setMeasurementToDisplay(
          measurementToDisplay.filter((meas) => meas !== selectedValue)
        )
      );
      dispatch(
        setDataPoints({
          ...setsToPlot,
          [selectedValue]: [],
        })
      );
      setDropDownValue(null);
    }
  };

  const itemRenderDropDown = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const currentMeasurement = itemProps.dataItem;
    const isSelected = measurementToDisplay.includes(currentMeasurement);
    const translatedKey = measurementKeys(currentMeasurement, t);

    if (!translatedKey) {
      return null; // Skip rendering if the key is undefined
    }

    return React.cloneElement(li, li.props, (
      <span
        className="MeasurementSelectorItemRender caption"
        style={{
          backgroundColor: isSelected ? "var(--secondary-color)" : "white",
        }}
      >
        <div
          className="MeasurementSelectorLine"
          style={{
            backgroundColor: measurementColors[currentMeasurement],
          }}
        />
        <div
          style={{
            fontSize: width >= cPhoneWidthLimStandard ? "14px" : "12px",
          }}
        >
          {translatedKey}
        </div>
      </span>
    ));
  };

  const valueRenderDropDown = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any
  ) => {
    return (
      <div className="MeasurementSelectorTagRender caption">
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", rowGap: "5px" }}>
          {measurementToDisplay.map((measurementKey) => (
            <div key={measurementKey} className="dropDownValueBox">
              <div
                className="MeasurementSelectorLine"
                style={{ backgroundColor: measurementColors[measurementKey] }}
              />
              <div
                style={{
                  fontSize: width <= cPhoneWidthLimStandard ? "14px" : "16px",
                }}
              >
                {measurementKeys(measurementKey, t)}
              </div>
              <span
                className="k-icon k-font-icon k-i-close removeChart"
                onClick={() => handleRemoveMeasurement(measurementKey)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleRemoveMeasurement = (measurementKey: string) => {
    dispatch(
      setDataPoints({
        ...setsToPlot,
        [measurementKey]: [],
      })
    );
    dispatch(
      setMeasurementToDisplay(
        measurementToDisplay.filter((meas) => meas !== measurementKey)
      )
    );
    setDropDownValue(null);
  };

  const handleRemoveAllMeasurements = () => {
    dispatch(
      setDataPoints({
        ...setsToPlot,
        trackGauge: [],
        crossLevel: [],
        crossLevelBIS: [],
        crossLevelUnevenness: [],
        twist3m: [],
        twist6m: [],
        alignment: [],
        longitudinalLevel: [],
        alignmentLeft: [],
        longitudinalLevelLeft: [],
        alignmentRight: [],
        longitudinalLevelRight: [],
        longitudinalAngleHP: [],
      })
    );
    dispatch(setMeasurementToDisplay([]));
    setDropDownValue(null);
  };

  return (
    <div className="DropdownContainer">
      <DropDownList
        id={`dropdowner${chartID}`}
        className="DropDownListToggleMeasurement"
        data={measurements}
        popupSettings={popupSettings}
        ref={multiSelectRefCloseMe}
        value={dropDownValue}
        itemRender={itemRenderDropDown}
        valueRender={valueRenderDropDown}
        filterable={false}
        onChange={handleDropDownChange}
      />
      {measurementToDisplay.length > 0 && (
        <span
          className="k-icon k-font-icon k-i-close removeChart"
          style={{
            fontSize: "20px",
            alignSelf: "center",
            marginLeft: "6px",
          }}
          onClick={() => handleRemoveAllMeasurements()}
        />
      )}
    </div>
  );
};
